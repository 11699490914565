import React, { useState } from "react";
import Layout from "../../../Layout";
import SectionHeading from "../../global/elements/SectionHeading";
import theme from "../../../theme";
import { Typography, Box } from "@mui/material";
import useGetInspiration from "../../../hooks/useGetInspiration";
import { singleInspirationDelete } from "../../../services/news-inspiration";
import { navigate } from "gatsby";
import { getUser } from "../../../services/auth";
import AppButton from "../../global/elements/AppButton";
import PropTypes from "prop-types";
import UniversalLoader from "../../global/elements/UniversalLoader";
import { submitCancelBox } from "../../../lib/styleConstants";

const SingleInspirationPage = ({ inspirationId = undefined }) => {
  const style = {
    main: {
      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
      borderRadius: theme.spacing(1.5),
      padding: { xs: "30px 20px", md: "30px 40px" },
      paddingBottom: theme.spacing(8),
      minHeight: "70vh",
      position: "relative",
    },

    blogImg: {
      width: theme.spacing(30),
      height: theme.spacing(20),
      borderRadius: theme.spacing(1),
    },
    loaderDiv: {
      paddingTop: "18%",
    },
  };
  const [inspiration, loading] = useGetInspiration(inspirationId);

  const user = getUser("user");

  const [btn_loading, setBtn_loading] = useState(false);

  const inspirationDeleteHandler = async () => {
    setBtn_loading(true);
    const status = await singleInspirationDelete(inspirationId);
    if (status.status == "Success") {
      setBtn_loading(false);
      navigate("/app/admin/inspirations");
    }
  };

  return (
    <Layout pageTitle="Inspirations" hasSideMenu loading={loading}>
      {loading ? (
        <Box sx={style.loaderDiv}>
          <UniversalLoader />
        </Box>
      ) : (
        <>
          <SectionHeading
            heading={
              inspiration?.title?.length > 50
                ? inspiration?.title?.slice(0, 50) + "..."
                : inspiration?.title
            }
            backgroundColor={theme.palette.grey.warmGrey}
          />
          <br />
          <Box sx={style.main}>
            <Typography variant="h5">{inspiration?.title}</Typography>

            <br />
            <img src={inspiration?.blogImage} style={style.blogImg} alt="image"/>
            <br />
            <br />
            <Typography variant="body1" style={style.description}>
              {inspiration?.description}
            </Typography>
            <br />

            <Box sx={submitCancelBox}>
              <AppButton
                look="inverted"
                noIcon
                label="Back"
                url={
                  user.role === "admin"
                    ? `/app/admin/inspirations`
                    : `/app/dashboard/info`
                }
                internalLink
              />
              {user.role == "admin" && (
                <AppButton
                  look="black"
                  label="Edit"
                  url={`/app/admin/inspirations/edit/${inspirationId}`}
                  internalLink
                />
              )}
            </Box>
          </Box>
          <br />
        </>
      )}
    </Layout>
  );
};

SingleInspirationPage.propTypes = {
  inspirationId: PropTypes.string,
};

// SingleInspirationPage.defaultProps = {
//   inspirationId: undefined,
// };

export default SingleInspirationPage;
